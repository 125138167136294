@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --backgroundColor: #eff6f7;

  --backDarkBlue: #0c5371;
  --backMiddleBlue: #97bac7;
  --backLightBlue: #cfdce3;
  --backBackBlue: #eff6f7;

  --textDarkBlue: #103c4b;
  --textLightBlue: #889da5;
  --textJaugeBlue: #7da1ae;
  --turquoise: #50b2c0;

  --orange: rgb(227, 108, 65);
  --vermilion: #e03616;
  --crayola: #eec584;

  --blueSecondary: #b0d7ff;
  --blueFourth: #7da1ae;
  --blue-border: rgba(16, 60, 75, 0.15);

  --input-background: #eff6f6;

  --mainPolice: "HK Grotesk", sans-serif;
}

body {
  @apply text-blue-deep-dark w-full h-full bg-blue-light;
  font-family: "HK Grotesk";
}

h1,
h2,
h3,
h4,
h5,
p,
li {
  @apply text-blue-deep-dark font-mainpolice;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

@layer components {
  /* DASHBOARD PAGE */
  .equivalence {
    @apply py-5 flex flex-col h-full items-center text-center;
  }

  .estimation_badge {
    @apply w-full !p-10;
  }

  .estimation_badge:not(:last-child) {
    @apply border border-blue-border;
  }

  .equivalence .wrapper-card-dashboard {
    @apply h-[40%] flex items-center justify-center;
  }

  .equivalence .wrapper-card-dashboard img {
    @apply w-10 h-10;
  }

  .equivalence .wrapper-card-dashboard ~ div {
    @apply h-[60%];
  }

  .equivalence .wrapper-card-dashboard ~ div p:first-child {
    @apply font-bold text-[#0F3D4A] text-lg;
  }

  .not-equivalence {
    @apply py-5 flex flex-col h-full items-center text-center;
  }

  .not-equivalence .wrapper-card-dashboard {
    @apply h-[40%] flex items-center justify-center;
  }

  .not-equivalence .wrapper-card-dashboard ~ div {
    @apply h-[60%];
  }

  .not-equivalence .wrapper-card-dashboard ~ div p:first-child {
    @apply font-bold text-[#0F3D4A] text-lg;
  }

  .not-equivalence .wrapper-card-dashboard img {
    @apply w-10 h-10;
  }

  /* ESTIMATION PAGE */
  #estimation input {
    --tw-ring-shadow: 0 0 #000 !important;
  }

  /* MainLayoutOnBoard */
  #signup_page,
  #forgotpass_page,
  #sendemail_page {
    margin: 30px 0% 5% 70px;
  }

  /* Admin Page */
  #admin .nav_dashboard_row {
    @apply py-6 hover:font-bold transition-all;
  }

  #admin .nav_dashboard_row .category_active {
    @apply font-bold flex items-center;
  }

  #admin .nav_dashboard_row:not(:last-child) {
    @apply border-b border-blue-border;
  }

  .user_credits input {
    @apply w-20 mr-1;
  }

  #parameter-country-1 {
    @apply border-t border-blue-border pt-4;
  }

  /* NEW */
  .blue-button {
    @apply bg-blue-dark border border-blue-dark hover:border-blue-deep-dark font-semibold hover:bg-blue-deep-dark text-white rounded-md text-center px-4 py-2 disabled:cursor-default disabled:border-gray-400 disabled:bg-gray-400 disabled:hover:bg-gray-400;
  }
  .yellow-button {
    @apply bg-yellow-mustard font-semibold hover:bg-yellow-light text-blue-dark text-center rounded-md px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-yellow-mustard;
  }
  .orange-button {
    @apply bg-orange font-semibold hover:bg-vermilion text-white text-center rounded-md px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-vermilion;
  }
  .orange-sepia-button {
    @apply border border-orange bg-white font-semibold hover:border-vermilion hover:text-vermilion text-orange text-center rounded-md 
    py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-vermilion;
  }
  .red-button {
    @apply bg-vermilion font-semibold hover:bg-orange text-white text-center rounded-md px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-vermilion;
  }
  .empty-button {
    @apply border border-blue-deep-dark hover:bg-primary text-blue-deep-dark font-semibold text-center rounded-md px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-transparent;
  }
  .white-button {
    @apply bg-white hover:bg-blue-mid text-blue-deep-dark font-semibold text-center rounded-md px-4 py-2 transition ease-linear duration-150 disabled:opacity-40 disabled:cursor-default disabled:hover:bg-transparent;
  }

  .input {
    @apply bg-white font-mainpolice border-0 rounded-lg h-9 text-base font-normal p-3 text-inherit disabled:opacity-60;
  }
  .checkbox {
    @apply w-4 h-4 border border-blue-deep-dark rounded-sm mr-2;
  }
  /* END - NEW */

  /* Dashboard badge */
  .amount_badge {
    border: #eeeeee solid 1px;
    background-color: white;
  }

  .emission_badge {
    @apply bg-[#eeeeee];
  }

  .bilan_badge {
    @apply text-[#0f3d4a] bg-[#dde2e4];
  }

  .certified_badge {
    @apply bg-[#d7f6da];
  }

  .category_active::before {
    content: "\00a0";
    @apply w-2 h-6 bg-blue-dark p-0.5 rounded-sm absolute -ml-5;
  }
}

#admin_stats .estimations_table .MuiDataGrid-columnHeaderTitle {
  @apply text-xs font-bold text-blue-deep-dark;
}

#admin_stats .estimations_table .MuiDataGrid-cell {
  @apply text-[11px] text-blue-deep-dark;
}

.slick-prev:before,
.slick-next:before {
  @apply !text-blue-deep-dark;
}

.to_not_print {
  @apply hidden;
}

/* ESTIMATION */
.form_row .MuiTypography-root {
  @apply text-blue-deep-dark font-mainpolice;
}

.form_row > p:nth-child(3) {
  @apply text-right;
}

.form_row > p:nth-child(3) span {
  @apply ml-4;
}

.form_row_1 {
  @apply h-16 text-[#b0d7ff] px-4 mb-5;
}

.firstCategory:not(:first-child) .form_row_1 {
  @apply mt-10;
}

.form_row_1 > p,
.form_row_2 > p {
  @apply font-bold text-xl;
}

.form_row_2 {
  @apply h-14 border-b border-blue-dark-150 mb-5;
}
.form_row_2 > p {
  @apply font-bold text-xl;
}
.form_row_2 > :first-child {
  @apply bg-[#b0d7ff] w-4/5;
}

.form_row_3 {
  @apply mt-6 mb-3;
}

.form_row_3 > p {
  @apply font-bold text-base;
}

.form_row_4 {
  @apply mt-5 mb-3;
}

.form_row_4 > p {
  @apply text-[#889da5] italic text-base;
}

.form_row_null {
  @apply mb-2;
}

.form_row_null > p {
  @apply font-normal text-base;
}

.nav_row {
  @apply w-full flex items-center pr-2.5;
}

.nav_row_1 {
  @apply py-4 pl-4 font-medium text-base;
}

.nav_row_1.active {
  @apply font-bold;
}

.nav_row_1.active::before {
  content: "\00a0";
  @apply w-2 h-6 bg-blue-dark p-0.5 rounded-sm absolute -ml-5;
}

.nav_row_1:not(.nav_row_1:first-of-type) {
  @apply border-t border-blue-dark-150;
}

.nav_row_1:hover > p {
  @apply font-extrabold;
}

.nav_row_2 {
  @apply py-1.5 pl-5 text-xs mb-1.5;
}

.nav_row_2 > p:first-of-type {
  @apply pr-1.5 mr-1.5;
}

.nav_row_2:hover > p {
  @apply font-semibold;
}

/* MODAL */
@media screen and (max-width: 1150px) {
  .nav_row > p:nth-child(2) {
    @apply w-[calc(85%-10px)];
  }
  .nav_row > p:last-of-type {
    @apply hidden;
  }
}
/* END ESTIMATION */

.scrollbar::-webkit-scrollbar {
  @apply w-2.5 h-2 bg-blue-light;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-blue-mid rounded-sm;
}

.scrollbar::-webkit-scrollbar-track {
  @apply bg-blue-light rounded-sm;
}

/* PRINTING */

.to_print {
  display: block;
}

.to_not_print {
  display: none;
}

.action_plan,
.results_category_title_and_charts,
.results_detailed_category,
.results_legend_and_table,
.treemap_section {
  page-break-inside: avoid;
}

#treemap,
.treemap_legend td > div,
.results_legend_and_table td > div {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.statistic_badge {
  print-color-adjust: exact;
}

.page_break_before {
  page-break-before: always;
}

#additional_information {
  padding-top: 20px;
  width: 100%;
}

#additional_information h3 {
  margin-bottom: 20px;
}

#additional_information h4 {
  margin-bottom: 12px;
}

#additional_information h4:not(:first-of-type) {
  margin-top: 18px;
}

#additional_information p {
  margin-bottom: 9px;
}

@media (orientation: landscape) {
  @page {
    size: "A4 landscape";
    margin: 20px;
  }
}

@media print {
  @page {
    size: "A4 portrait";
    margin: 20px;
  }

  html,
  body {
    padding: 0;
    margin: 0;
  }

  body {
    overflow: visible;
    background-color: white;
    -webkit-print-print-color-adjust: exact;
  }
}

/* END OF PRINTING */
