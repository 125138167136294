/* GENERAL */

/* everything rendered as img in html2canvas if we set display = inline it will not take extra space andmore than itself  */
body img {
  display: inline !important;
}

/* NAV */

.nav_results_row {
  padding: 25px 0px 25px 10px;
  /* grid not adapted to align ::before */
  display: flex;
  align-items: center;
}

.nav_results_row:not(:last-child) {
  border-bottom: 1px solid var(--blue-border);
}

.nav_results_row > div:first-child {
  width: 13%;
}
.nav_results_row > div:first-child p {
  width: fit-content;
  margin-right: 10px;
}

.nav_results_row:hover p,
.category_active p {
  font-weight: 800;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
}

/* Compensation */

.compensation_box {
  margin-bottom: 32px;
}

#modal_compensation .InfoBox {
  margin-top: 16px;
}

/* RESULTS */

#results_header {
  display: none;
  margin-bottom: 50px;
}

#results_header > img {
  width: 120px;
}

#results_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 64px; */
}

#results_title .secoya_logo_wrapper {
  width: 120px;
  height: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#results_title .secoya_logo_wrapper img {
  max-width: 100%;
  max-height: 100%;
}

#results_title h1 {
  margin-bottom: 8px;
}

#results_title h2 {
  margin-bottom: 20px;
}

.results_category_title {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--blue-border);
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.results_category_title > p {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.result_statistics {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Main infos */

/* .main_infos_box {
    margin-top: 20px;
    width: 100%;
    padding: 20px 64px 4px;
    display: flex;
    flex-wrap: wrap;
} */

.main_info {
  width: 50%;
  display: grid;
  grid-template-columns: calc(50% - 16px) calc(50% - 16px);
  column-gap: 32px;
  margin-bottom: 16px;
}

.main_info > p:first-child {
  justify-self: flex-end;
  text-align: right;
  align-self: center;
}

.main_info > p:last-child {
  justify-self: flex-start;
  text-align: left;
  align-self: center;
}

.treemap_section {
  width: 100%;
}

#treemap {
  width: 100%;
  /* margin: 20px 0px 20px; */
  height: 220px;
}

.treemap_label_wrapper p {
  font-weight: bold;
}

#results .recharts-tooltip-wrapper {
  z-index: 100;
  visibility: visible !important;
}

.tooltip_box {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: white;
  border: 1px solid var(--blue-border);
  border-radius: 2px;
}

.bar_chart .tooltip_box {
  margin-left: -200px;
}

.tooltip_box * {
  white-space: pre;
}

.tooltip_box > div {
  height: 10px;
  width: 10px;
  margin-right: 10px;
}

.results_category {
  width: 100%;
}

#action_plan_wrapper_for_print {
  padding-top: 20px;
}

.action_plan #action_plan_button_wrapper {
  margin-top: 40px;
  display: grid;
  column-gap: 15px;
  width: 100%;
  grid-template-columns: 300px 220px;
}

/* Legend & Table Box */

.treemap_legend {
  width: 100%;
  border: 1px solid var(--blue-border);
  padding: 3px 8px 3px 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

#treemap_legend_wrapper {
  display: none;
}

.results_legend_and_table {
  margin-bottom: 16px;
}

.results_legend_and_table table {
  width: 100%;
  margin-bottom: 32px;
  border: 1px solid var(--blue-border);
  padding: 3px 8px 3px 8px;
}

.treemap_legend.results_legend_and_table table {
  width: 45%;
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.results_legend_and_table tr th {
  font-weight: 700;
  font-size: 12.8px;
  line-height: 17px;
}

.results_legend_and_table thead th {
  padding-top: 5px;
  padding-bottom: 5px;
}

.results_legend_and_table tr td {
  font-style: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 17px;
}

.results_legend_and_table tr td:not(:first-child, :nth-child(2)) {
  text-align: right;
}

.results_legend_and_table tr td:nth-child(2) {
  /* padding-left: 10px; */
}

.results_legend_and_table tr td:first-child {
  width: 26px;
}

.results_legend_and_table tr td:first-child > div {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
  padding: 0;
}

/* ------------------- */

.go_further_box p:not(:last-child) {
  margin-bottom: 16px;
}

.chart_container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 2px;
  display: grid;
  grid-template-rows: 200px 32px;
  align-items: center;
  row-gap: 15px;
  justify-self: center;
}

.chart_container.no_title {
  grid-template-rows: 250px;
}

.pie_container {
  width: 99%;
  height: 100%;
}
@media screen and (max-width: 1150px) {
  .chart_container p {
    justify-self: center;
    text-align: center;
  }

  .footnote {
    display: none;
  }

  .chart_container {
    width: min(100%, 200px);
    grid-template-rows: 200px 42px;
    row-gap: 15px;
  }
  .chart_container.no_title {
    grid-template-rows: 200px;
  }
}

.to_print,
.to_print_portrait,
.to_print_landscape {
  display: none;
}

/* PRINT */

.page_break_before {
  page-break-before: always;
}

#additional_information {
  padding-top: 20px;
  width: 100%;
}

#additional_information h3 {
  margin-bottom: 20px;
}

#additional_information h4 {
  margin-bottom: 12px;
}

#additional_information h4:not(:first-of-type) {
  margin-top: 18px;
}

#additional_information p {
  margin-bottom: 9px;
}

@media print {
  @page {
    size: "A4 portrait";
    margin: 20px;
  }

  html,
  body {
    padding: 0;
    margin: 0;
  }

  body {
    overflow: visible;
    background-color: white;
    -webkit-print-print-color-adjust: exact;
  }
}
/* .treemap_section */
.to_not_print,
.treemap_section {
  display: none;
}

.statistic_badge {
  print-color-adjust: exact;
}

#treemap,
.treemap_legend td > div,
.results_legend_and_table td > div {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.action_plan,
.results_category_title_and_charts,
.results_detailed_category,
.results_legend_and_table,
.treemap_section {
  page-break-inside: avoid;
}

@media (orientation: landscape) {
  @page {
    size: "A4 landscape";
    margin: 20px;
  }
}
